.container {
    text-align: center;
    width: 100%;
}

.loading {
    margin-top: 1rem;
}

.description {
    margin: 1rem auto 0;
}
