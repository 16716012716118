.accountSelectContainer{
    position: relative;
    cursor: pointer;
}
.accountSelectorList{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    transform: translateY(calc(100% + 20px));
    flex-direction: column;
    z-index: 10000;
    border: 1px solid #cfdced;
    background: white;
    border-radius: 4px;
    padding: 10px 0;
}
.openAccountSelector{
    display: flex;
}
.accountSelectorItem{
    display: flex;
    flex-direction: column;
    padding: 10px 18px;
}
.accountSelectorItem:first-child{
    border-radius: 4px 4px 0 0;
}
.accountSelectorItem:last-child{
    border-radius: 0 0 4px 4px;
}
.accountSelectorItem:hover{
    background: #6291ce;
}
.accountSelectorItem:hover div{
    color: white;
}
.accountSelectorItemTitle{
    margin-bottom: 4px;
    font-weight: bold;
    color: #102856;
    font-size: 14px;
}
.accountSelectorItemDescription{
    color: #879aaa;
    font-size: 12px;
}