.remote-ui-group-items
{
    margin-left: 5px;
}

.remote-ui-object
{
    padding: 10px;
    border: 1px dashed #909090;
}

.remote-ui-list-item
{
    margin: 3px;
    width: 100%;
}

.remote-ui-list-item-remove
{
    width: 1%;
    white-space: nowrap;
    height: 100%;
    vertical-align: top;
}

.remote-ui-list-item-handle
{
    height: 100%;
    width: 30px;
    background: repeating-linear-gradient(

            #909090,
            #909090 1px,
            transparent 1px,
            transparent 4px
    );
}

.remote-ui-description
{
    color: #909090;
    font-weight: bold;
    font-size: smaller;
    white-space: pre;
}

.remote-ui-editor label
{
    margin-bottom: 0px;
    margin-top: 3px;
    font-weight: bold;
}

.remote-ui-editor input
{
    margin-top: 3px;
}

.remote-ui-nullable-add-container
{
    cursor: pointer;
    padding: 3px;
    text-align: center;
    border-radius: 3px;
    width: 100%;
    background-color: #bfbfbf;
}

label.remote-ui-error
{
    color: #b00000;
}

.remote-ui-error-string
{
    color: #a03030;
}