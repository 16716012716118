html,
body,
div#app {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    font-family: "Open Sans", sans-serif;
}

button {
    outline: none !important;
}

label {
    cursor: pointer;
    margin: 0;
}

/* HACK: react datepicker doesn't have fullwidth mechanism by default */
.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container > input[type="text"] {
    width: 100%;
}

/* Hide weird thing turning our dropdown into multiline text box. */
.react-dropdown-select-input[placeholder=''] {
    display: none;
}

.react-dropdown-select[disabled] {
    opacity: 1 !important;
    background-color: #e9ecef !important;
    color: #495057 !important;
}
