.Logo__avatar {
    width: 42px;
    min-width: 42px;
    height: 42px;
    min-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: rgba(255,255,255,0.5);
    margin-right: 19px;
    font-size: 20px;
    color: #ffffff;
}

.Logo__avatar img{
    width: 100%;
}

.Logo__title {
    color: #ffffff;
    font-size: 14px;
    line-height: 24px;
}

.Logo__subtitle {
    color: rgba(255, 255, 255, 0.6);
    font-size: 13px;
}

.dark .Logo__title {
    color: #ffffff;
}

.dark .Logo__subtitle {
    color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 940px) {
    .headerLogoMobile {
        width: fit-content;
        margin: auto;
    }
    .headerLogoMobile .Logo__avatar{
        display: none;
    }
    .headerLogoMobile .Logo__text {
        justify-content: center;
        text-align: center;
    }
    .headerLogoMobile .Logo__title{
        font-size: 13px;
    }
    .headerLogoMobile .Logo__subtitle{
        font-size: 12px;
    }
}