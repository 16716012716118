.spinner {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border: .25em solid;
    border-right: .25em solid transparent;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    animation: bp-spin .75s linear infinite;
}

.spinner__variant_important {
    border-color: #f3f3f3;
    border-top-color: #c22353;
}
.spinner__variant_primary {
    border-color: #2862ac;
    border-top-color: #ffffff;
}
.spinner__variant_secondary {
    border-color: #6d757d;
    border-top-color: #ffffff;
}

.spinner__size_small {
    width: 1rem;
    height: 1rem;
    border-width: .15em;
    border-right-width: .15em;
}
.spinner__size_large {
    width: 4rem;
    height: 4rem;
    border-width: .5em;
    border-right-width: .5em;
}

@keyframes bp-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
