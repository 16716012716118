.langChooser{
    border: 0;
    background: transparent;
    color: #a9c0de;
    width: max-content;
    text-transform: capitalize;
}

.langChooser option{
    color: black;
}

.langChooser:focus{
    outline: none;
}